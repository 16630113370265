import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { graphql } from 'gatsby';

import Layout from '@sus-core/components/scaffolding/Layout';

import { SubCategoryGrid } from '@sus-core/components/category/SubCategoryGrid';
import { removeQueryParams } from '@sus-core/utils/url';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';
import { FilterableProducts } from '@sus-core/components/product/FilterableProducts';
import { ProductTrackingProvider } from '@sus-core/hooks/tracking/TrackingContext';
import { PageProvider } from '@sus-core/hooks/page/PageContext';

import { SusPageProps } from '@sus-core/hooks/page/SusPageProps';
import { useAppService } from '@sus-core/state/xstate/app/useAppService';

type CategoryWithProductsPageProps = SusPageProps<
  GatsbyTypes.categoryPageWithProductsQuery,
  { categoryId: string; catId: number }
>;

function CategoryWithProductsPage({
  data,
  location,
  pageContext,
}: CategoryWithProductsPageProps) {
  const { getFilters } = useAppService();

  const aggregations = []; //data.sus.products.aggregations;
  const category = data.magentoCategory;
  const products = useMemo(
    () => data.allMagentoProduct.edges.map(e => e.node),
    [data.allMagentoProduct.edges]
  );

  const breadcrumbs = undefined;
  const {
    meta_description,
    meta_keywords,
    meta_title,
    canonical_url,
    description,
    name,
    // products: rawProducts,
    // breadcrumbs,
  } = category;

  const categoryUrl = removeQueryParams(canonical_url);
  const imageData = data.magentoCategory?.children;
  const subCategories = useMemo(
    () => data.magentoCategory?.children?.filter(cat => cat.include_in_menu),
    [data.magentoCategory?.children]
  );

  const hasSubCategories = category.children?.length > 0;

  // reset filters when user navigates to another page
  useEffect(() => {
    const filterActor = getFilters(
      category.id,
      aggregations,
      pageContext.attributeMetaData
    );
    return () => {
      if (filterActor) {
        filterActor.send({ type: 'FILTER_RESET' });
      }
    };
  }, []);

  return (
    <PageProvider
      data={{
        pageType: 'Category',
        category: name,
        categoryId: category.magento_id,
        categoryUrlPath: category.url_path,
        attributeMetaData: pageContext.attributeMetaData,
      }}>
      <ProductTrackingProvider
        value={{ category: name, scope: 'Category Page' }}>
        <Layout
          location={location}
          meta={{
            title: meta_title,
            description: meta_description,
            keywords: meta_keywords,
            canonical: categoryUrl,
            robots: ['index', 'follow'],
          }}
          content={{ heading: category.name, html: description }}
          showBreadcrumbs
          breadcrumbs={breadcrumbs}
          currentPageName={category?.name}>
          <SubCategoryGrid
            images={imageData}
            className={clsx(PAGE_CONTAINER_CSS, 'px-8')}
            categories={subCategories}
          />

          {hasSubCategories && (
            <hr className={clsx(PAGE_CONTAINER_CSS, 'border-gray-4 my-16')} />
          )}

          <FilterableProducts
            categoryId={pageContext.categoryId}
            className={clsx(PAGE_CONTAINER_CSS)}
            aggregations={aggregations}
            products={products}
            meta={pageContext.attributeMetaData}
          />
        </Layout>
      </ProductTrackingProvider>
    </PageProvider>
  );
}

export default CategoryWithProductsPage;

/*
products(filter: { category_id: { eq: $categoryId } }) {
  aggregations {
    attribute_code
    count
    label
    options {
      count
      label
      value
    }
  }
}


*/

export const query = graphql`
  query categoryPageWithProducts($catId: Int!) {
    magentoCategory(magento_id: { eq: $catId }) {
      id
      magento_id
      position
      name
      meta_description
      meta_keywords
      meta_title
      url_path
      canonical_url
      description
      include_in_menu
      children {
        ... on MagentoCategory {
          include_in_menu
          position
          name
          canonical_url
          magento_id
          image {
            large: childImageSharp {
              gatsbyImageData(
                breakpoints: [200]
                height: 305
                width: 508
                transformOptions: { fit: CONTAIN }
              )
            }
            small: childImageSharp {
              gatsbyImageData(
                breakpoints: [200]
                height: 195
                width: 325
                transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
      }
    }
    allMagentoProduct(
      filter: { categories: { elemMatch: { id: { eq: $catId } } } }
      sort: { fields: price_range___minimum_price___final_price___value }
    ) {
      edges {
        node {
          sku
          __typename

          options {
            required
          }

          id
          sku
          name
          categories {
            id
            url_path
          }

          canonical_url

          stock_status
          min_sales_qty
          price_range {
            maximum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
            minimum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
          }
          price_tiers {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            quantity
          }
          image {
            childImageSharp {
              id
              gatsbyImageData(
                breakpoints: [200]
                width: 200
                height: 200
                transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
      }
    }
  }
`;
