import clsx from 'clsx';
import React, { HTMLAttributes, useMemo } from 'react';

import {
  GatsbyImage,
  IGatsbyImageData,
  withArtDirection,
} from 'gatsby-plugin-image';

import { RootLink } from '../navigation/RootLink';
import { removeQueryParams } from '@sus-core/utils/url';
import { Icons } from '../icon/Icons';
import { CategoryImageData } from './types';

export interface CategoryTileData {
  canonical_url: string;
  name: string;
}

export function CategoryTile({
  category,
  className,
  imageData,
}: {
  imageData: CategoryImageData;
  category: CategoryTileData;
} & HTMLAttributes<HTMLDivElement>) {
  const images = useMemo(
    () =>
      imageData?.image?.large?.gatsbyImageData
        ? withArtDirection(imageData?.image?.large?.gatsbyImageData, [
            {
              media: '(max-width: 700px)',
              image: imageData?.image?.small?.gatsbyImageData,
            },
          ])
        : (null as IGatsbyImageData),
    [imageData]
  );

  return (
    <div className={className}>
      <RootLink
        className={clsx(
          'flex flex-col h-full items-center group text-gray-1 text-center text-13 md:text-15 font-semibold'
        )}
        to={removeQueryParams(category.canonical_url)}>
        <div
          className={clsx(
            'flex-1',
            'p-0',
            'border-default border-gray-5 rounded-lg overflow-hidden',
            'shadow-md hover:shadow-lg transition-shadow'
          )}>
          {images && <GatsbyImage image={images} alt={category.name || ''} />}
        </div>

        <span className="flex items-center mt-2 p-2 h-50px">
          {category.name}
          <Icons.ArrowLeft className="ml-1 transform rotate-180 group-hover:translate-x-2 transition-transform delay-75" />
        </span>
      </RootLink>
    </div>
  );
}
