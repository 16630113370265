import React, { useMemo } from 'react';

import { CategoryTile, CategoryTileData } from './CategoryTile';
import { Grid } from '../layout/Grid';
import { removeQueryParams } from '@sus-core/utils/url';
import clsx from 'clsx';
import { CategoryImages } from './types';

export interface SubcategoryGridData extends CategoryTileData {
  magento_id: number;
  position: number;
}

export function SubCategoryGrid({
  categories,
  className,
  images,
}: {
  categories: readonly SubcategoryGridData[];
  className?: string;
  images: CategoryImages;
}) {
  const sortedCategories = useMemo(
    () => categories.concat()?.sort((a, b) => a.position - b.position) || [],
    categories
  );

  return (
    <Grid
      className={clsx(className, 'gap-4 md:gap-8 px-4 md:px-8 xxl:gap-x-28')}>
      {sortedCategories.map(subCategory => (
        <div
          key={removeQueryParams(subCategory.canonical_url)}
          className="w-full h-full">
          <CategoryTile
            imageData={images?.find(
              i => i.magento_id === subCategory.magento_id
            )}
            key={removeQueryParams(subCategory.canonical_url)}
            className="h-full"
            category={subCategory}
          />
        </div>
      ))}
    </Grid>
  );
}
