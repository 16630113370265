import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { ProductGrid, ProductGridProps } from './ProductGrid';
import { filterProducts } from '@sus-core/api/service/products';
import { useTracking } from '@sus-core/hooks/tracking/useTracking';

import {
  getFilterInputParams,
  ProductFilterProps,
  ProductFilters,
} from './ProductFilters';
import {
  Aggregations,
  AttributeMetaData,
  filtersAreEmpty,
} from '@sus-core/state/xstate/filter/filter.machine';

import { FilterTrigger } from './FilterTrigger';
import { MEDIA_QUERY, useMediaQuery } from '@sus-core/hooks/useMediaQuery';

export type FilterableProductsProps = {
  categoryId?: string;
  className?: string;
  meta?: AttributeMetaData;
  products: ProductGridProps['products'];
  aggregations: Aggregations;
};

export function FilterableProducts({
  categoryId,
  className,
  products,
  aggregations,
  meta = [],
}: FilterableProductsProps) {
  const isMobile = useMediaQuery(MEDIA_QUERY.IS_MOBILE);
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredProducts, setFilteredProducts] =
    useState<ProductGridProps['products']>(products);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  //// Tracking
  const { productsImpression } = useTracking();
  useEffect(() => {
    productsImpression(filteredProducts);
  }, [filteredProducts]);
  ////

  const handleFilterChange: ProductFilterProps['onChange'] = (
    filterValues,
    updateAggregations,
    _meta
  ) => {
    if (filtersAreEmpty(filterValues)) {
      setFilteredProducts(products);
      return;
    }

    setLoading(true);
    filter(meta, filterValues, categoryId)
      .then(result => {
        setFilteredProducts(
          products.filter(p =>
            result.data.products.items.find(i => i.sku + '' === p.sku + '')
          )
        );

        updateAggregations(result.data.products.aggregations);
      })
      .finally(() => setLoading(false));
  };

  return (
    <section
      className={clsx(
        'px-8',
        'flex items-start',
        'flex-col md:flex-row',
        className
      )}>
      {!isMobile && (
        <ProductFilters
          filterId={categoryId}
          className="w-full max-w-250 mr-4"
          loading={loading}
          meta={meta}
          aggregations={aggregations}
          onChange={handleFilterChange}
        />
      )}
      {isMobile && (
        <FilterTrigger
          className="mb-4 w-full"
          resultCount={filteredProducts?.length}
          filterId={categoryId}
          loading={loading}
          meta={meta}
          aggregations={aggregations}
          onChange={handleFilterChange}
        />
      )}
      <ProductGrid className="flex-3" products={filteredProducts} />
    </section>
  );
}

function filter(
  meta: AttributeMetaData,
  filterValues: Record<string, any>,
  categoryId?: string
) {
  const params = getFilterInputParams(meta, filterValues, categoryId);
  return filterProducts(params);
}
